import { createTaskApiAttributes } from "../types/api_types";
import { apiCall } from "../utils/api_util.service";

interface TaskQuestion {
  id: string;
  question: string;
  asked_by: {
    id: string;
    name: string;
  };
  is_answered: boolean;
  answers: TaskAnswer[];
  created_on: string;
  can_ask: boolean;
}

interface TaskAnswer {
  id: string;
  answer: string;
  answered_by: {
    id: string;
    name: string;
  };
  created_on: string;
}

interface ApiResponse<T> {
  data?: T;
  detail?: string;
}

//Create Task Api service
export const createTaskApi = async (task_data: createTaskApiAttributes) => {
  try {
    const data = await apiCall("task/", "POST", task_data);
    return data;
    // Process the received data
  } catch (error) {
    // Handle error here
    return error;
  }
};

//Get Task Api service
export const getAllTaskApi = async (params: any) => {
  try {
    const data = await apiCall("retrieve-task/?" + params);
    return data;
    // Process the received data
  } catch (error) {
    // Handle error here
    return error;
  }
};

//Get Task by ID Api service
export const getTaskByIdApi = async (id: any) => {
  try {
    const data = await apiCall("retrieve-task/" + id + "/");
    return data;
    // Process the received data
  } catch (error) {
    // Handle error here
    return error;
  }
};

//Update Task Api service
export const UpdateTaskApi = async (task_data: any, id: number) => {
  try {
    const data = await apiCall("task/" + id + "/", "PUT", task_data);
    return data;
    // Process the received data
  } catch (error) {
    // Handle error here
    return error;
  }
};

//Get Task Questions Api service
export const getTaskQuestionsApi = async (
  taskId: string | number
): Promise<ApiResponse<TaskQuestion[]>> => {
  try {
    const data: any = await apiCall(`questions/${taskId}/`);
    return data;
  } catch (error) {
    return error as ApiResponse<TaskQuestion[]>;
  }
};

//Create Task Question Api service
export const createTaskQuestionApi = async (
  taskId: string | number,
  question: string
): Promise<ApiResponse<TaskQuestion>> => {
  try {
    const data: any = await apiCall(`questions/${taskId}/`, "POST", {
      question,
    });
    return data;
  } catch (error) {
    return error as ApiResponse<TaskQuestion>;
  }
};

//Create Task Answer Api service
export const createTaskAnswerApi = async (
  questionId: string | number,
  answer: string
): Promise<ApiResponse<TaskAnswer>> => {
  try {
    const data: any = await apiCall(`answers/${questionId}/`, "POST", {
      answer,
    });
    return data;
  } catch (error) {
    return error as ApiResponse<TaskAnswer>;
  }
};
