import { apiCall } from "../utils/api_util.service";

export const getCategoriesApi = async (keyword: string = "") => {
  try {
    const data = await apiCall(`categories/?keyword=${keyword}`);
    return data;
  } catch (error) {
    return error;
  }
};

export const getSubCategoriesApi = async (
  categoryId: number,
  keyword: string = ""
) => {
  try {
    const data = await apiCall(
      `subcategories/?category_id=${categoryId}&keyword=${keyword}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
