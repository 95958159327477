import { useState, useEffect } from "react";
import "./TaskDetails.scss";
import CustomModal from "../../../../components/Modal/Modal";
import { UpdateTask } from "../../UpdateTask/UpdateTask";
import { CreateBid } from "../../../BidManagement/CreateBid/CreateBid";
import DOMPurify from "dompurify";
import { ViewAttachment } from "../../../../components/ViewAttachment/ViewAttachment";
import { Spinner } from "../../../../components/Spinner/Spinner";
import { toast } from "react-toastify";
import {
  getTaskQuestionsApi,
  createTaskQuestionApi,
  createTaskAnswerApi,
} from "../../../../services/task.service";

interface TaskQuestion {
  id: string;
  question: string;
  asked_by: {
    id: string;
    name: string;
  };
  is_answered: boolean;
  answers: TaskAnswer[];
  created_on: string;
  can_ask: boolean;
}

interface TaskAnswer {
  id: string;
  answer: string;
  answered_by: {
    id: string;
    name: string;
  };
  created_on: string;
}

export const TaskDetails = ({ taskDetails, recallTaskData }: any) => {
  const [attachment, Attachments] = useState([1, 2, 3, 4]);
  const [updatemodalShow, setUpdateModalShow] = useState(false);
  const toggleUpdateModal = () => setUpdateModalShow(!updatemodalShow);
  const [viewBidmodalShow, setViewBidModalShow] = useState(false);
  const toggleBidViewModal = () => setViewBidModalShow(!viewBidmodalShow);
  const [skills, setSkills] = useState([
    "Web Design",
    "Figma",
    "Mobile UI",
    "User Experience Design",
    "Responsive Design",
  ]);
  const [questions, setQuestions] = useState<TaskQuestion[]>([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [newAnswer, setNewAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(
    null
  );

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response:any = await getTaskQuestionsApi(taskDetails.id);
      if (response.detail) {
        toast.error(response.detail);
      } else if (response.data) {
        console.log(response.data?.data)
        setQuestions(response.data);
      }
    } catch (error: any) {
      toast.error(error.response?.data?.detail || "Failed to fetch questions");
    } finally {
      setLoading(false);
    }
  };

  const handleAskQuestion = async () => {
    try {
      setLoading(true);
      const response = await createTaskQuestionApi(taskDetails.id, newQuestion);
      if (response.detail) {
        toast.error(response.detail);
      } else {
        setNewQuestion("");
        fetchQuestions();
        toast.success("Question posted successfully");
      }
    } catch (error: any) {
      toast.error(error.response?.data?.detail || "Failed to post question");
    } finally {
      setLoading(false);
    }
  };

  const handleAnswerQuestion = async (questionId: string) => {
    try {
      setLoading(true);
      const response = await createTaskAnswerApi(questionId, newAnswer);
      if (response.detail) {
        toast.error(response.detail);
      } else {
        setNewAnswer("");
        setSelectedQuestionId(null);
        fetchQuestions();
        toast.success("Answer posted successfully");
      }
    } catch (error: any) {
      toast.error(error.response?.data?.detail || "Failed to post answer");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (taskDetails && taskDetails?.id && taskDetails.communication_type === "closed") {
      fetchQuestions();
    }
  }, [taskDetails]);

  return (
    <>
      <div className="row">
        <div className="task-desc pt-4 row">
          {/* <div className="col-8">{taskDetails && taskDetails.description}</div> */}
          <div
            className="col-lg-10 col-md-10 col-12 order-lg-1 order-md-1 order-2"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                taskDetails && taskDetails.description.replace(/\n/g, "<br />")
              ),
            }}
          />
          <div className="col-lg-2 col-md-2 col-12 d-flex justify-content-end order-lg-2 order-md-2 order-1 pb-lg-0 pb-md-0 pb-4">
            <div>
              {localStorage.getItem("e_type") == "1" ? (
                <button
                  className="task-btn"
                  onClick={() => toggleUpdateModal()}
                >
                  Edit Task
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="acceptance-header pt-4">Acceptance Criteria :</div>
      {/* <div className="acceptance-desc">
        {taskDetails && taskDetails.acceptance_criteria}
      </div> */}

      <div
        className="acceptance-desc"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            taskDetails &&
              taskDetails.acceptance_criteria.replace(/\n/g, "<br />")
          ),
        }}
      />
      <div className="row bottom-border">
        <div className="col-lg-6 col-md-6 col-12 skills-header">
          Skills & Expertise
          <div className="skills-type">
            {taskDetails && taskDetails.experience_level}
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-12">
          <div className="skills-container2 flex-lg-row flex-md-column flex-column">
            {taskDetails &&
              taskDetails.skills &&
              taskDetails.skills.map((skills: any) => (
                <div className=" skill-wrapper pb-3 " key={skills.skill}>
                  <div className="skill">{skills.skill}</div>
                </div>
              ))}
          </div>
        </div>
      </div>

      {((localStorage.getItem("j_type") == "1" &&
        taskDetails &&
        taskDetails.bid_type) ||
        localStorage.getItem("e_type") == "1") && (
        <div className="row bottom-border">
          <div className="col-lg-6 col-md-6 col-12 skills-header">
            Placed Bids
          </div>

          <div className="col-lg-6 col-md-6 col-12 d-flex flex-lg-row flex-md-row flex-column">
            <div className="min-bid me-lg-5 me-md-5 me-0 mb-lg-0 mb-md-0 mb-3">
              Min Bid Price:{" "}
              {taskDetails && taskDetails.min_bid_value
                ? taskDetails.min_bid_value
                : "N/A"}{" "}
              {taskDetails && taskDetails.currency}
            </div>
            <div className="min-bid">
              Max Bid Price:{" "}
              {taskDetails && taskDetails.max_bid_value
                ? taskDetails.max_bid_value
                : "N/A"}{" "}
              {taskDetails && taskDetails.currency}
            </div>
          </div>
        </div>
      )}

      <div>
        <div className="attachment-header">Attachments</div>
        <ViewAttachment attachments={taskDetails && taskDetails.attachments} />
      </div>

      {taskDetails && taskDetails.communication_type == "closed" && (
        <div className="qa-forum mt-4">
          <div className="qa-header mb-4">Questions & Answers</div>

          {/* Ask Question Section */}
        {taskDetails.created_by != localStorage.getItem("user_id") &&
                  (taskDetails.origin_organization && taskDetails.origin_organization.id &&
                    localStorage.getItem("org_id") !=
                      taskDetails.origin_organization.id) &&  <div className="ask-question-section mb-4">
            <textarea
              className="form-control mb-3"
              rows={3}
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
              placeholder="Ask your question here..."
            />
            <button
              className="task-btn"
              onClick={handleAskQuestion}
              disabled={!newQuestion.trim() || loading}
            >
              {loading ? <Spinner /> : "Ask Question"}
            </button>
          </div>}

          {/* Questions List */}
          <div className="questions-list">
            {questions && questions.map((question) => (
              <div
                key={question.id}
                className="question-thread mb-4 p-3 border rounded"
              >
                <div className="question-header d-flex justify-content-between align-items-start mb-2">
                  <div>
                    <span className="q-name">{question.asked_by.name}</span>
                    <small className="text-muted ms-2">
                      {new Date(question.created_on).toLocaleDateString()}
                    </small>
                  </div>
                  {question.is_answered && (
                    <span className="badge bg-success">Answered</span>
                  )}
                </div>
                <div className="question-content mb-3">{question.question}</div>

                {/* Answers */}
                {question.answers && 
                  question.answers.map((answer: any) => (
                    <div
                      key={answer.id}
                      className="answer ms-4 mt-3 p-3 bg-light rounded"
                    >
                      <div className="answer-header d-flex justify-content-between align-items-start mb-2">
                        <div>
                          <span className="q-name">{answer.answered_by.name}</span>
                          <small className="text-muted ms-2">
                            {new Date(answer.created_on).toLocaleDateString()}
                          </small>
                        </div>
                      </div>
                      <div className="answer-content">{answer.answer}</div>
                    </div>
                  ))}

                {/* Answer Form - Only visible for task owner or org members */}
                {(taskDetails.created_by === localStorage.getItem("user_id") ||
                  (taskDetails.origin_organization && taskDetails.origin_organization.id &&
                    localStorage.getItem("org_id") ==
                      taskDetails.origin_organization.id)) &&
                  selectedQuestionId === question.id && (
                    <div className="answer-form ms-4 mt-3">
                      <textarea
                        className="form-control mb-2"
                        rows={2}
                        value={newAnswer}
                        onChange={(e) => setNewAnswer(e.target.value)}
                        placeholder="Write your answer..."
                      />
                      <div className="d-flex gap-2">
                        <button
                          className="task-btn"
                          onClick={() => handleAnswerQuestion(question.id)}
                          disabled={!newAnswer.trim() || loading}
                        >
                          {loading ? <Spinner /> : "Post Answer"}
                        </button>
                        <button
                          className="task-btn btn-secondary"
                          onClick={() => setSelectedQuestionId(null)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}

                {/* Show Answer Button - Only visible for task owner or org members */}
                {(taskDetails.created_by === localStorage.getItem("user_id") ||
                  (taskDetails.origin_organization &&
                    localStorage.getItem("org_id") ==
                      taskDetails.origin_organization.id)) &&
                  selectedQuestionId !== question.id && (
                    <button
                      className="btn btn-link mt-2"
                      onClick={() => setSelectedQuestionId(question.id)}
                    >
                      Answer this question
                    </button>
                  )}
              </div>
            ))}
          </div>
        </div>
      )}

      <CustomModal
        show={updatemodalShow}
        toggle={toggleUpdateModal}
        ModalHeader="Update Task"
      >
        <UpdateTask
          task_id=""
          taskData={taskDetails}
          closeModal={toggleUpdateModal}
          recallData={recallTaskData}
        />
      </CustomModal>

      <CustomModal
        show={viewBidmodalShow}
        toggle={toggleBidViewModal}
        ModalHeader="Place Bid"
      >
        <CreateBid details={taskDetails} closeModal={toggleBidViewModal} />
      </CustomModal>
    </>
  );
};
