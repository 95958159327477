import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import { createTaskApiAttributes } from "../../../types/api_types";
import InputField from "../../../core/InputField/InputField";
import "./UpdateTask.scss";
import SelectField from "../../../core/SelectField/SelectField";
import Button from "../../../core/Button/Button";
import {
  UpdateTaskApi,
  createTaskApi,
  getTaskByIdApi,
} from "../../../services/task.service";
import { ApiAttributes, TaskUpdateProps } from "../../../types/types";
import { ErrorNotification } from "../../../components/ErrorNotification/ErrorNotification";
import { toast } from "react-toastify";
import { BsDash, BsPlusLg } from "react-icons/bs";
import { useEffect, useState } from "react";
import ImageUpload from "../../../components/FileUpload/FileUpload";
import CustomModal from "../../../components/Modal/Modal";
import { SkillClient } from "../../../components/SkillClient/SkillClient";
import { ViewAttachment } from "../../../components/ViewAttachment/ViewAttachment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AddSubContractors from "../CreateTask/AdvancedOption/AddSubContractors";
import { useUserRole } from "../../../hooks/HasRole";
import { Spinner } from "../../../components/Spinner/Spinner";
import * as Yup from "yup";

interface Skill {
  id: number;
  skill: string;
}

interface SkillsProps {
  skills: Skill[];
  updateSkillList: (skills: Skill[]) => void;
}

export const UpdateTask = ({
  task_id,
  taskData,
  closeModal,
  recallData,
}: TaskUpdateProps) => {
  const [skills, setSkills] = useState(["English", "Photoshop"]);
  const [files, setFiles] = useState<any[]>();
  const [existingFiles, setExistingFiles] = useState<any[]>([]);
  const [skillList, setSkillList] = useState<Skill[]>(taskData?.skills);
  const [modalShow, setModalShow] = useState(false);
  const toggleModal = () => setModalShow(!modalShow);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAccept, setIsLoadingAccept] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);
  const [selectedSubContractor, setSelectedSubContractor] = useState<any>([]);
  const [task, setTasks] = useState<any>();
  const { roles, hasRole, hasOrganization } = useUserRole();

  useEffect(() => {
    getTaskByID();
  }, [task_id]);

  const getTaskByID = async () => {
    let task_data: any = await getTaskByIdApi(task_id);
    console.log(task_data);
    if (task_data.status == 200) {
      setTasks(task_data.data);
      setSkillList(task_data.data.skills);
      if (task_data.data.attachments) {
        setExistingFiles(task_data.data.attachments);
      }
    }
  };

  const initialValues = {
    title: task?.title ? task.title : "",
    description: task?.description ? task.description : "",
    budget: task?.budget ? parseInt(task.budget) : 0,
    currency: task?.currency ? task.currency : "SEK",
    bid_type: task?.bid_type ? task.bid_type : "",
    communication_type: task?.communication_type ? task.communication_type : "",
    bid_deadline: task?.bid_deadline ? task.bid_deadline : "",
    communication_deadline: task?.communication_deadline
      ? task.communication_deadline
      : "",
    task_deadline: task?.task_deadline ? task.task_deadline : "",
    required_skills: skillList ? skillList.map((skill) => skill.id) : [],
    sub_contractors: selectedSubContractor
      ? selectedSubContractor
          .filter((user: any) => user.type === "worker")
          .map((user: any) => user.id)
      : [],
    sub_organization_ids: selectedSubContractor
      ? selectedSubContractor
          .filter((user: any) => user.type === "company")
          .map((user: any) => user.id)
      : [],
    acceptance_criteria: task?.acceptance_criteria
      ? task.acceptance_criteria
      : "",
    exit_criteria: task?.exit_criteria ? task.exit_criteria : "",
    job_type: task?.job_type ? task.job_type : "",
    perdiem_type: task?.perdiem_type ? task.perdiem_type : "",
    experience_level: task?.experience_level ? task.experience_level : "entry",
    files: files,
    updated_on: new Date().toISOString(),
  };

  // Add validation schema
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, "Too Short!")
      .max(60, "Too Long!")
      .required("Required"),
    description: Yup.string().required("Required"),
    budget: Yup.number().required("Required"),
    currency: Yup.string().required("Required"),
    bid_type: Yup.string().required("Required"),
    bid_deadline: Yup.date()
      .required("Required")
      .test(
        "is-future-date",
        "Bid deadline must be in the future",
        (value) => value && new Date(value) > new Date()
      ),
    communication_deadline: Yup.date().test(
      "is-future-date",
      "Communication deadline must be in the future",
      (value) => value && new Date(value) > new Date()
    ),
    task_deadline: Yup.date()
      .required("Required")
      .test(
        "is-future-date",
        "Task deadline must be in the future",
        (value) => value && new Date(value) > new Date()
      )
      .test(
        "is-after-bid-deadline",
        "Task deadline must be after bid deadline",
        function (value) {
          const { bid_deadline } = this.parent;
          return (
            value && bid_deadline && new Date(value) > new Date(bid_deadline)
          );
        }
      ),
    acceptance_criteria: Yup.string().required("Required"),
    job_type: Yup.string().required("Required"),
    files: Yup.array(),
  });

  // Handle form submission
  const onSubmit = async (
    values: createTaskApiAttributes,
    { setSubmitting }: any
  ) => {
    values.required_skills = await skillList.map((skill) => skill.id);
    if (selectedSubContractor.length > 0) {
      values.sub_contractors = await selectedSubContractor
        .filter((user: any) => user.type === "worker")
        .map((user: any) => user.id);
      values.sub_organization_ids = await selectedSubContractor
        .filter((user: any) => user.type === "company")
        .map((user: any) => user.id);
    } else {
      delete values.sub_contractors;
      delete values.sub_organization_ids;
    }

    // Combine existing and new files
    if (files && files.length > 0) {
      values.files = files;
    }

    setIsLoading(true);
    const task_request: any = await UpdateTaskApi(
      values,
      task?.id ? task?.id : 0
    );
    setIsLoading(false);
    if (task_request.status == 200) {
      toast.success("task updated successfully");
      closeModal();
      recallData();
    } else {
      ErrorNotification(task_request.message);
    }
  };

  const handleFilesChange = async (
    files: { file: string }[],
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    console.log("Uploaded Files:", files);
    setFiles(files);
    setFieldValue("files", files);
  };

  const handleFileDelete = async (fileId: number) => {
    setExistingFiles(existingFiles.filter((file) => file.id !== fileId));
    // You may want to add an API call here to delete the file from the server
  };

  const handleSkillUpdate = (newSkills: Skill[]) => {
    setSkillList(newSkills);
    // updateSkillList(newSkills);
  };

  const ApproveTask = async (isApproved: any) => {
    if (isApproved) {
      setIsLoadingAccept(true);
    } else {
      setIsLoadingReject(true);
    }

    let data_values = {
      is_post_approved: isApproved,
    };
    const task_request: any = await UpdateTaskApi(
      data_values,
      task?.id ? task?.id : 0
    );
    setIsLoadingAccept(false);
    setIsLoadingReject(false);
    if (task_request.status == 200) {
      toast.success("task updated successfully");
      closeModal();
      recallData();
    } else {
      ErrorNotification(task_request.message);
    }
  };

  return (
    <div>
      {task ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {(formik) => (
            <Form className="ps-5 pe-5">
              <div className="row">
                <div className="col-12 label pt-2 pb-2">Title</div>

                <div className="col-12">
                  <InputField
                    label=""
                    className="task-input mb-4"
                    name="title"
                    isDisabled={false}
                    fieldType="text"
                    placeholder={""}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 label pb-2">Description</div>
                <div className="col-12 pb-3">
                  <Field name="description">
                    {({ field, form }: FieldProps) => (
                      <CKEditor
                        editor={ClassicEditor}
                        data={field.value}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          form.setFieldValue(field.name, data);
                        }}
                      />
                    )}
                  </Field>
                </div>
              </div>
              {/* 
            <div className="row">
              <div className="col-12 label pb-2">Skill</div>

              <div className="col-12">
                <InputField
                  label=""
                  className="task-input mb-4"
                  name="task_skill"
                  isDisabled={false}
                  fieldType="text"
                  placeholder={""}
                />
              </div>
            </div> */}

              <div className="row">
                <div className="col-6  pt-2">
                  <div className="label pb-2">Task Deadline</div>
                  <div>
                    <InputField
                      label=""
                      className="task-input mb-4 w-50"
                      name="task_deadline"
                      isDisabled={false}
                      fieldType="datetime-local"
                      placeholder={""}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="label mb-3">Bid Type</div>
                  <div className="row">
                    <div className="col-4">
                      <div className="">
                        <div className=" pe-5 type-box">
                          <Field
                            type="radio"
                            name="bid_type"
                            className="me-2"
                            value="open"
                          />
                          <span className="terms">Open Bid</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="">
                        <div className=" pe-5 type-box">
                          <Field
                            type="radio"
                            name="bid_type"
                            className="me-2"
                            value="closed"
                          />
                          <span className="terms">Close Bid</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="label pt-2">Task Budget</div>

                  <div className="col-12 pt-2">
                    {/* <InputField
                    label=""
                    className="task-input mb-4"
                    name="budget"
                    isDisabled={false}
                    fieldType="number"
                    placeholder={""}
                  /> */}

                    <div className="input-group d-flex">
                      <InputField
                        label=""
                        className="budget-input mb-4"
                        name="budget"
                        isDisabled={false}
                        fieldType="number"
                        placeholder={""}
                      />
                      <div className="input-group-append">
                        <SelectField
                          name="currency"
                          options={[
                            {
                              value: "USD",
                              label: "USD",
                            },
                            {
                              value: "SEK",
                              label: "SEK",
                            },
                            {
                              value: "EUR",
                              label: "EUR",
                            },
                          ]}
                          label={""}
                          className="currency-select mb-3"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-5">
                <div className="label pt-2">Currency</div>

                <div className="col-9 pt-2">
                  <SelectField
                    name="currency"
                    options={[
                      {
                        value: "USD",
                        label: "USD",
                      },
                      {
                        value: "SEK",
                        label: "SEK",
                      },
                      {
                        value: "EUR",
                        label: "EUR",
                      },
                    ]}
                    label={""}
                    className="task-input mb-3"
                  />
                </div>
              </div> */}
              </div>

              <div className="row">
                <div className="col-6  pt-2">
                  <div className="label pb-2">Bid End Date</div>
                  <div>
                    <InputField
                      label=""
                      className="task-input mb-4 w-50"
                      name="bid_deadline"
                      isDisabled={false}
                      fieldType="datetime-local"
                      placeholder={""}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 label  pb-2">Acceptance Criteria</div>

                <div className="col-12 pb-3">
                  <Field name="acceptance_criteria">
                    {({ field, form }: FieldProps) => (
                      <CKEditor
                        editor={ClassicEditor}
                        data={field.value}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          form.setFieldValue(field.name, data);
                        }}
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div>
                <div className="col-12 label  pb-2">Skills</div>

                <div className="row">
                  {skillList &&
                    skillList.map((skill: any) => (
                      <div className="col-2">
                        <div className="skill-box">
                          {skill.skill} <BsDash />
                        </div>
                      </div>
                    ))}
                  <div className="col-2">
                    <div className="skill-box" onClick={() => toggleModal()}>
                      Add More <BsPlusLg />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-4">
                <div className="label mb-3">Task Type (optional)</div>
                <div className="row">
                  <div className="col-2">
                    <div className="">
                      <div className=" pe-5 type-box">
                        <Field
                          type="radio"
                          name="job_type"
                          className="me-2"
                          value="remote"
                        />
                        <span className="terms">Online</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="">
                      <div className=" pe-5 type-box">
                        <Field
                          type="radio"
                          name="job_type"
                          className="me-2"
                          value="physical"
                        />
                        <span className="terms">Physical</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="">
                      <div className=" pe-5 type-box">
                        <Field
                          type="radio"
                          name="job_type"
                          className="me-2"
                          value="hybrid"
                        />
                        <span className="terms">Hybrid</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-4">
                <div className="label mb-3">Attachment</div>
                {existingFiles && existingFiles.length > 0 && (
                  <div className="mb-3">
                    <div className="label">Existing Attachments</div>
                    <ViewAttachment
                      attachments={existingFiles}
                      onDelete={handleFileDelete}
                      showDelete={true}
                    />
                  </div>
                )}
                <div className="label">Add New Attachments</div>
                <ImageUpload
                  onFilesChange={(files) =>
                    handleFilesChange(files, formik.setFieldValue)
                  }
                />
              </div>
              {["task_manager", "admin"].some((role: any) => hasRole(role)) && (
                <AddSubContractors
                  selectedSubContractor={selectedSubContractor}
                  setSelectedSubContractor={setSelectedSubContractor}
                />
              )}

              <div className="row">
                <div className="col-lg-6 col-md-6 col-12  pt-2">
                  <div className="label mb-3">Communication Type</div>
                  <div className="row mb-3">
                    <div className="col-lg-4 col-md-4 col-6">
                      <div
                        className="pe-5 type-box"
                        onClick={() =>
                          formik.setFieldValue("communication_type", "open")
                        }
                      >
                        <Field
                          type="radio"
                          name="communication_type"
                          className="me-2"
                          value="open"
                        />
                        <span className="terms">Open</span>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-6">
                      <div
                        className="pe-5 type-box"
                        onClick={() =>
                          formik.setFieldValue("communication_type", "closed")
                        }
                      >
                        <Field
                          type="radio"
                          name="communication_type"
                          className="me-2"
                          value="closed"
                        />
                        <span className="terms">Closed</span>
                      </div>
                    </div>
                  </div>
                  <ErrorMessage
                    name="communication_type"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4 col-md-4 col-12 pt-2">
                  <div className="label pb-2">Bid Deadline</div>
                  <div>
                    <InputField
                      label=""
                      className="task-input mb-4 task-deadline"
                      name="bid_deadline"
                      isDisabled={false}
                      fieldType="datetime-local"
                      placeholder={""}
                    />
                    <ErrorMessage
                      name="bid_deadline"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-12 pt-2">
                  <div className="label pb-2">Task Deadline</div>
                  <div>
                    <InputField
                      label=""
                      className="task-input mb-4 task-deadline"
                      name="task_deadline"
                      isDisabled={false}
                      fieldType="datetime-local"
                      placeholder={""}
                    />
                    <ErrorMessage
                      name="task_deadline"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                {formik.values.communication_type === "open" && (
                  <div className="col-lg-4 col-md-4 col-12 pt-2">
                    <div className="label pb-2">Communication Deadline</div>
                    <div>
                      <InputField
                        label=""
                        className="task-input mb-4 task-deadline"
                        name="communication_deadline"
                        isDisabled={false}
                        fieldType="datetime-local"
                        placeholder={""}
                      />
                      <ErrorMessage
                        name="communication_deadline"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="row">
                <div className="col-12 label  pb-2">Exit Criteria</div>

                <div className="col-12 pb-3">
                  <Field name="exit_criteria">
                    {({ field, form }: FieldProps) => (
                      <CKEditor
                        editor={ClassicEditor}
                        data={field.value}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          form.setFieldValue(field.name, data);
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage
                    name="exit_criteria"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="col-12 mt-3">
                <div className="label mb-3">Task Type</div>
                <div className="row">
                  <div className="col-lg-2 col-md-4 col-6">
                    <div
                      className=""
                      onClick={() => formik.setFieldValue("job_type", "remote")}
                    >
                      <div className=" pe-5 type-box">
                        <Field
                          type="radio"
                          name="job_type"
                          className="me-2"
                          value="remote"
                        />
                        <span className="terms">Online</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-2 col-md-4 col-6">
                    <div
                      className=""
                      onClick={() => formik.setFieldValue("job_type", "onsite")}
                    >
                      <div className=" pe-5 type-box">
                        <Field
                          type="radio"
                          name="job_type"
                          className="me-2"
                          value="onsite"
                        />
                        <span className="terms">Physical</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-2 col-md-4 col-6 pt-lg-0 pt-md-0 pt-3">
                    <div
                      className=""
                      onClick={() => formik.setFieldValue("job_type", "hybrid")}
                    >
                      <div className=" pe-5 type-box">
                        <Field
                          type="radio"
                          name="job_type"
                          className="me-2"
                          value="hybrid"
                        />
                        <span className="terms">Hybrid</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-2 col-md-4 col-6 pt-lg-0 pt-md-0 pt-3">
                    <div
                      className=""
                      onClick={() =>
                        formik.setFieldValue("job_type", "perdiem")
                      }
                    >
                      <div className=" pe-5 type-box">
                        <Field
                          type="radio"
                          name="job_type"
                          className="me-2"
                          value="perdiem"
                        />
                        <span className="terms">Per Diem</span>
                      </div>
                    </div>
                  </div>

                  <ErrorMessage
                    name="job_type"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              {formik.values.job_type === "perdiem" && (
                <div className="col-12 mt-3">
                  <div className="label mb-3">Per Diem Type</div>
                  <div className="row">
                    <div className="col-lg-2 col-md-4 col-6">
                      <div
                        className=""
                        onClick={() =>
                          formik.setFieldValue("perdiem_type", "per_hour")
                        }
                      >
                        <div className=" pe-5 type-box">
                          <Field
                            type="radio"
                            name="perdiem_type"
                            className="me-2"
                            value="per_hour"
                          />
                          <span className="terms">Per Hour</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-4 col-6">
                      <div
                        className=""
                        onClick={() =>
                          formik.setFieldValue("perdiem_type", "per_day")
                        }
                      >
                        <div className=" pe-5 type-box">
                          <Field
                            type="radio"
                            name="perdiem_type"
                            className="me-2"
                            value="per_day"
                          />
                          <span className="terms">Per Day</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-4 col-6">
                      <div
                        className=""
                        onClick={() =>
                          formik.setFieldValue("perdiem_type", "per_week")
                        }
                      >
                        <div className=" pe-5 type-box">
                          <Field
                            type="radio"
                            name="perdiem_type"
                            className="me-2"
                            value="per_week"
                          />
                          <span className="terms">Per Week</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-4 col-6">
                      <div
                        className=""
                        onClick={() =>
                          formik.setFieldValue("perdiem_type", "per_month")
                        }
                      >
                        <div className=" pe-5 type-box">
                          <Field
                            type="radio"
                            name="perdiem_type"
                            className="me-2"
                            value="per_month"
                          />
                          <span className="terms">Monthly</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-3">
                <div className="d-flex justify-content-end mt-1 pb-lg-0 pb-4 ">
                  {task &&
                    task.is_post_approved == false &&
                    task.is_post_approved == false &&
                    ["admin", "task_manager"].some((role: any) =>
                      hasRole(role)
                    ) && (
                      <>
                        {" "}
                        <Button
                          className="task-btn me-2"
                          buttonText={"Approve Task"}
                          type="button"
                          isLoading={isLoadingAccept}
                          onClickHandler={() => ApproveTask(1)}
                        />
                        <Button
                          className="task-btn me-2"
                          buttonText={"Decline Task"}
                          type="button"
                          isLoading={isLoadingReject}
                          onClickHandler={() => ApproveTask(0)}
                        />
                      </>
                    )}

                  <Button
                    className="task-btn"
                    buttonText={"Update Task"}
                    type="submit"
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <Spinner size="large" />
      )}
      <CustomModal
        show={modalShow}
        toggle={toggleModal}
        size={"md"}
        ModalHeader="Add Skills"
      >
        <SkillClient
          skills={skillList}
          onSkillUpdate={handleSkillUpdate}
          close={toggleModal}
        />
      </CustomModal>
    </div>
  );
};
