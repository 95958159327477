import "./CustomProfile.scss";
import profile from "../../../assets/profile-img.png";
import defaultLogo from "../../../assets/bid-comp.png";
import { Formik } from "formik";
import InputField from "../../../core/InputField/InputField";
import React, { useEffect, useState } from "react";
import Button from "../../../core/Button/Button";
import SelectField from "../../../core/SelectField/SelectField";
import { ErrorNotification } from "../../../components/ErrorNotification/ErrorNotification";
import {
  getUserDetailsByIDApi,
  updateUserDetailsApi,
} from "../../../services/user.service";
import { toast } from "react-toastify";
import { Skills } from "../../../components/Skills/Skills";
import { Language } from "../../../components/Language/Language";
import { Certification } from "../../../components/Certification/Certification";
import { Project } from "../../../components/Project/Project";
import { useParams } from "react-router-dom";
import { getCountriesApi, Country } from "../../../services/country.service";

interface Skill {
  id: number;
  skill: string;
}

interface Language {
  id?: number | null;
  language: string | null;
  expertise_level: string | null;
  language_id: number | null;
  is_delete?: number | null;
}

interface Certification {
  id?: number | null;
  title: string;
  institution: string | null;
  description: string | null;
  from_date: string | null;
  to_date: string | null;
  is_delete?: number | null;
}

interface Project {
  id?: number | null;
  title: string;
  description: string | null;
  from_date: string | null;
  to_date: string | null;
  is_delete?: number | null;
}

export const CustomProfile = () => {
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState<any>();
  const [skillList, setSkillList] = useState<Skill[]>([]);
  const [languageList, setLanguageList] = useState<Language[]>([]);
  const [certificationList, setCertificationList] = useState<Certification[]>(
    []
  );
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    getUserDetails();
    const fetchCountries = async () => {
      const countriesData = await getCountriesApi();
      setCountries(countriesData);
    };
    fetchCountries();
  }, []);

  const getUserDetails = async () => {
    const user_details: any = await getUserDetailsByIDApi(id);
    if (user_details.status === 200) {
      setUserDetails(user_details.data);
      const skills: Skill[] = user_details.data.skills ?? [];
      setSkillList(skills);

      const languages: Language[] = user_details.data.languages ?? [];
      setLanguageList(languages);

      const certifications: Certification[] =
        user_details.data.certifications ?? [];
      setCertificationList(certifications);

      const projects: Project[] = user_details.data.projects ?? [];
      setProjectList(projects);
    } else {
      ErrorNotification(user_details.message);
    }
  };

  const updateSkillList = (newSkills: Skill[]) => {
    setSkillList(newSkills);
  };

  const updateLanguageList = (newLanguages: Language[]) => {
    setLanguageList(newLanguages);
  };

  const updateCertificationList = (newCertifications: Certification[]) => {
    setCertificationList(newCertifications);
  };

  const updateProjectList = (newProjects: Project[]) => {
    setProjectList(newProjects);
  };

  const initialValuesPersonal = {
    first_name: userDetails?.first_name || "",
    last_name: userDetails?.last_name || "",
    user_name: userDetails?.user_name || "",
    phone_no: userDetails?.phone_no || "",
    email: userDetails?.email || "",
    profile_image: "",
  };

  const onSubmitPersonal = async (values: any, { setSubmitting }: any) => {
    if (values.user_name === userDetails?.user_name) {
      delete values.user_name;
    }
    if (values.email === userDetails?.email) {
      delete values.email;
    }
    if (values.phone_no === userDetails?.phone_no) {
      delete values.phone_no;
    }

    const user_request: any = await updateUserDetailsApi(values);
    if (user_request.status === 200) {
      toast.success("User details updated successfully");
      getUserDetails();
    } else {
      ErrorNotification(user_request.message);
    }
  };

  const initialValuesOrg = {
    company_name: userDetails?.organization?.name || "",
    company_url: userDetails?.organization?.company_url || "",
    address_line1: userDetails?.organization?.address_line1 || "",
    country: userDetails?.organization?.country || "",
    address_line2: userDetails?.organization?.address_line2 || "",
    city: userDetails?.organization?.city || "",
    zip_code: userDetails?.organization?.zip_code || "",
    email: "",
  };

  const onSubmitOrg = async (values: any, { setSubmitting }: any) => {
    const user_request: any = await updateUserDetailsApi(values);
    if (user_request.status === 200) {
      toast.success("Organization details updated successfully");
      getUserDetails();
    } else {
      ErrorNotification(user_request.message);
    }
  };

  if (!userDetails) {
    return null;
  }

  return (
    <div className="p-5">
      <div className="profile-bg">
        {userDetails.has_organization === 1 ? (
          <>
            <div className="profile-banner mb-5">
              <img
                className="profile-img"
                width={"10%"}
                src={
                  userDetails?.organization?.logo_url
                    ? userDetails?.organization?.logo_url
                    : defaultLogo
                }
                alt="Organization Logo"
              />
            </div>

            <Formik initialValues={initialValuesOrg} onSubmit={onSubmitOrg}>
              {(formik) => (
                <div className="p-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="label pt-2 pb-2">Organization Name</div>
                      <InputField
                        label=""
                        className="task-input mb-4"
                        name="company_name"
                        isDisabled={false}
                        fieldType="text"
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="label pt-2 pb-2">Organization Address</div>
                    <div className="row">
                      <div className="col-6">
                        <InputField
                          label=""
                          className="task-input mb-4"
                          name="address_line1"
                          isDisabled={false}
                          fieldType="text"
                          placeholder="Line 1"
                        />
                      </div>

                      <div className="col-6">
                        <InputField
                          label=""
                          className="task-input mb-4"
                          name="address_line2"
                          isDisabled={false}
                          fieldType="text"
                          placeholder="Line 2"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <InputField
                          label=""
                          className="task-input mb-4"
                          name="city"
                          isDisabled={false}
                          fieldType="text"
                          placeholder="City"
                        />
                      </div>

                      <div className="col-6">
                        <InputField
                          label=""
                          className="task-input mb-4"
                          name="zip_code"
                          isDisabled={false}
                          fieldType="text"
                          placeholder="Postal Code"
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <SelectField
                        name="country"
                        options={countries.map((country) => ({
                          value: country.code,
                          label: country.name,
                        }))}
                        label=""
                        className="task-input mb-3"
                        LabelclassName="register-lbl"
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="label pt-2 pb-2">Organization Web URL</div>
                    <InputField
                      label=""
                      className="task-input mb-4"
                      name="company_url"
                      isDisabled={false}
                      fieldType="text"
                      placeholder=""
                    />
                  </div>

                  <div className="col-12">
                    <div className="label pt-2 pb-2">Email</div>
                    <InputField
                      label=""
                      className="task-input mb-4"
                      name="email"
                      isDisabled={false}
                      fieldType="text"
                      placeholder=""
                    />
                  </div>
                </div>
              )}
            </Formik>
          </>
        ) : (
          <>
            <div className="profile-banner">
              <img
                className="profile-img"
                width={"10%"}
                src={profile}
                alt="Profile"
              />
            </div>

            <div className="profile-name">
              {userDetails?.first_name || "John D."}
            </div>

            <div className="profile-location">
              Stockholm, Sweden - 11:14 am local
            </div>

            <Formik
              initialValues={initialValuesPersonal}
              onSubmit={onSubmitPersonal}
              enableReinitialize
            >
              {(formik) => (
                <div className="p-5">
                  <div className="row">
                    <div className="col-6">
                      <div className="label pt-2 pb-2">First Name</div>
                      <InputField
                        label=""
                        className="task-input mb-4"
                        name="first_name"
                        isDisabled={false}
                        fieldType="text"
                        placeholder=""
                      />
                    </div>

                    <div className="col-6">
                      <div className="label pt-2 pb-2">Last Name</div>
                      <InputField
                        label=""
                        className="task-input mb-4"
                        name="last_name"
                        isDisabled={false}
                        fieldType="text"
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="label pt-2 pb-2">User Name</div>
                    <InputField
                      label=""
                      className="task-input mb-4"
                      name="user_name"
                      isDisabled={false}
                      fieldType="text"
                      placeholder=""
                    />
                  </div>

                  <div className="col-12">
                    <div className="label pt-2 pb-2">Email</div>
                    <InputField
                      label=""
                      className="task-input mb-4"
                      name="email"
                      isDisabled={false}
                      fieldType="text"
                      placeholder=""
                    />
                  </div>

                  <div className="col-12">
                    <div className="label pt-2 pb-2">Phone</div>
                    <InputField
                      label=""
                      className="task-input mb-4"
                      name="phone_no"
                      isDisabled={false}
                      fieldType="text"
                      placeholder=""
                    />
                  </div>
                </div>
              )}
            </Formik>
          </>
        )}
      </div>
      {userDetails.has_organization !== 1 && (
        <>
          <Skills
            skills={skillList}
            updateSkillList={updateSkillList}
            disableUpdate={true}
          />
          <Language
            languages={languageList}
            updateLanguageList={updateLanguageList}
            disableUpdate={true}
          />
          <Certification
            certifications={certificationList}
            updateCertificationList={updateCertificationList}
            disableUpdate={true}
          />
          <Project
            projects={projectList}
            updateProjectList={updateProjectList}
            disableUpdate={true}
          />
        </>
      )}
    </div>
  );
};
