import React, { useState, useRef } from "react";
import "./ProfilePictureSelector.scss";
import defaultAvatar from "../../assets/profile-img.png";
import CustomModal from "../Modal/Modal";
import Button from "../../core/Button/Button";
import { BsUpload } from "react-icons/bs";

interface ProfilePictureSelectorProps {
  currentImage: string;
  onImageSelect: (base64Image: string) => void;
  onClose: () => void;
  isOpen: boolean;
}

export const ProfilePictureSelector: React.FC<ProfilePictureSelectorProps> = ({
  currentImage,
  onImageSelect,
  onClose,
  isOpen,
}) => {
  const [selectedTab, setSelectedTab] = useState<
    "upload" | "camera" | "avatar"
  >("upload");
  const [previewImage, setPreviewImage] = useState<string>(currentImage);
  const [selectedFileName, setSelectedFileName] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const avatars = [defaultAvatar, defaultAvatar];

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFileName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setPreviewImage(base64String);
        onImageSelect(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCameraCapture = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const video = document.createElement("video");
      video.srcObject = stream;
      await video.play();

      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext("2d")?.drawImage(video, 0, 0);

      const base64Image = canvas.toDataURL("image/jpeg");
      setPreviewImage(base64Image);
      onImageSelect(base64Image);

      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const convertImageToBase64 = (imgUrl: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous"; // This is important for CORS
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0);
        const base64String = canvas.toDataURL("image/png");
        resolve(base64String);
      };
      img.onerror = (error) => reject(error);
      img.src = imgUrl;
    });
  };

  const handleAvatarSelect = async (avatarSrc: string) => {
    try {
      const base64Image = await convertImageToBase64(avatarSrc);
      setPreviewImage(base64Image);
      onImageSelect(base64Image);
    } catch (error) {
      console.error("Error converting avatar to base64:", error);
    }
  };

  return (
    <CustomModal
      show={isOpen}
      toggle={onClose}
      ModalHeader="Update Profile Picture"
      size="lg"
    >
      <div className="profile-picture-selector">
        <div className="tabs">
          <button
            className={`tab ${selectedTab === "upload" ? "active" : ""}`}
            onClick={() => setSelectedTab("upload")}
          >
            Upload Photo
          </button>
          <button
            className={`tab ${selectedTab === "camera" ? "active" : ""}`}
            onClick={() => setSelectedTab("camera")}
          >
            Take Photo
          </button>
          <button
            className={`tab ${selectedTab === "avatar" ? "active" : ""}`}
            onClick={() => setSelectedTab("avatar")}
          >
            Choose Avatar
          </button>
        </div>

        <div className="preview">
          {previewImage && (
            <img src={previewImage} alt="Preview" className="preview-image" />
          )}
        </div>

        <div className="content">
          {selectedTab === "upload" && (
            <div className="upload-section">
              <input
                type="file"
                accept="image/*"
                onChange={handleFileUpload}
                className="file-input"
                id="file-input"
                ref={fileInputRef}
              />
              <label htmlFor="file-input" className="file-input-label">
                <BsUpload />
                Choose File
              </label>
              {selectedFileName && (
                <div className="file-name">{selectedFileName}</div>
              )}
            </div>
          )}

          {selectedTab === "camera" && (
            <div className="camera-section">
              <Button
                buttonText="Capture Photo"
                onClickHandler={handleCameraCapture}
                className="capture-btn"
              />
            </div>
          )}

          {selectedTab === "avatar" && (
            <div className="avatar-section">
              <div className="avatar-group">
                <h4>Default Avatars</h4>
                <div className="avatar-list">
                  {avatars.map((avatar, index) => (
                    <img
                      key={`avatar-${index}`}
                      src={avatar}
                      alt={`Avatar ${index + 1}`}
                      onClick={() => handleAvatarSelect(avatar)}
                      className={previewImage === avatar ? "selected" : ""}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </CustomModal>
  );
};
