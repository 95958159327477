// export const remote_url_v1 = "https://api-prod-main.sparetan.com/api/v1/"

// export const remote_chat_url_v1 = "wss://api-prod-chat.sparetan.com/"

// export const remote_faceid_url_v1 = "https://api-prod-faceid.sparetan.com/"

// export const remote_url_v1 = "http://95.216.206.214:8000/api/v1/"

export const remote_url_v1 = "https://api.sparetan.com/api/v1/"
//export const remote_url_v1 = "http://localhost:8000/api/v1/"

export const remote_chat_url_v1 = "wss://chat.sparetan.com/"

export const remote_faceid_url_v1 = "http://95.216.206.214:8000/"

export const REACT_APP_STRIPE_PUBLISHABLE_KEY="pk_test_51PY522HbzdnihNWQNwo0dSqhEBB0NkndDEJShQFhNKYi1HEU3tJOZsbFuzvSzpAyT6rS95MpFob535wgAZzOpXDP00LO6egY7d"