import { remote_url_v1 } from "../environment/environment";
import axios from "axios";

export interface Country {
  code: string;
  name: string;
}

export const getCountriesApi = async (): Promise<Country[]> => {
  try {
    const response = await axios.get(`${remote_url_v1}countries/`);
    return response.data;
  } catch (error) {
    console.error("Error fetching countries:", error);
    return [];
  }
};
