import { Field, Form, Formik } from "formik";
import InputField from "../../../core/InputField/InputField";
import SelectField from "../../../core/SelectField/SelectField";
import Button from "../../../core/Button/Button";
import { createBidApi } from "../../../services/bid.service";
import { toast } from "react-toastify";
import { ErrorNotification } from "../../../components/ErrorNotification/ErrorNotification";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";

interface Cost {
  cost: number;
  currency: string;
  description: string;
}

interface CreateBidProps {
  details: any;
  closeModal: () => void;
}

export const CreateBid = ({ details, closeModal }: CreateBidProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [costs, setCosts] = useState<Cost[]>([]);

  const getAmountLabel = () => {
    if (details.bid_type === "per_diem") {
      switch (details.per_diem_period) {
        case "hourly":
          return "Enter hourly rate";
        case "day":
          return "Enter daily rate";
        case "week":
          return "Enter weekly rate";
        default:
          return "Enter amount";
      }
    }
    return "Enter amount";
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required("Amount is required")
      .test("max-budget", "Amount exceeds maximum budget", function (value) {
        if (details.bid_type === "max_price" && details.budget) {
          return value <= details.budget;
        }
        return true;
      }),
    description: Yup.string().required("Description is required"),
    message: Yup.string().required("Message is required"),
    cover_letter: Yup.string().required("Cover letter is required"),
    revision: Yup.number()
      .required("Number of revisions is required")
      .min(1, "At least 1 revision must be offered")
      .max(20, "Maximum 20 revisions allowed")
      .integer("Number of revisions must be a whole number"),
  });

  const initialValues = {
    amount: "",
    description: "",
    currency: details.currency,
    message: "",
    revision: 3,
    cover_letter: "",
    task_id: details.id,
  };

  const addCost = () => {
    setCosts([
      ...costs,
      { cost: 0, currency: details.currency, description: "" },
    ]);
  };

  const updateCost = (
    index: number,
    field: keyof Cost,
    value: string | number
  ) => {
    const newCosts = [...costs];
    newCosts[index] = { ...newCosts[index], [field]: value };
    setCosts(newCosts);
  };

  const removeCost = (index: number) => {
    setCosts(costs.filter((_, i) => i !== index));
  };

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    setIsLoading(true);
    const bidData = {
      ...values,
      costs: costs,
    };

    const bid_request: any = await createBidApi(bidData);
    setIsLoading(false);

    if (bid_request.status === 201) {
      toast.success("Bid created successfully");
      closeModal();
    } else {
      ErrorNotification(bid_request.message);
    }
  };

  return (
    <div className="create-bid-container">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form className="ps-5 pe-5">
            <div
              className="input-group d-flex mb-4"
              style={{ flexDirection: "column" }}
            >
              <label className="label  pb-2">{getAmountLabel()}</label>
              <div className="d-flex">
                <InputField
                  label=""
                  className="budget-input"
                  name="amount"
                  isDisabled={false}
                  fieldType="number"
                  is_error={false}
                  placeholder="Enter amount"
                />

                <div className="input-group-append">
                  <SelectField
                    isDisabled={true}
                    name="currency"
                    options={[
                      { value: details.currency, label: details.currency },
                    ]}
                    label=""
                    className="currency-select"
                  />
                </div>
              </div>
              {errors.amount && touched.amount && (
                <div className="error-message text-danger">{errors.amount}</div>
              )}
            </div>

            <div className="col-12 label  pb-2">Description</div>

            <div className="col-12">
              <Field
                as="textarea"
                name="description"
                className="task-input-desc mb-4"
              />
            </div>

            <div className="mb-4"> 
              <label className="label pb-2">Number of Revisions</label>
              <InputField
                label=""
                className="task-input"
                name="revision"
                isDisabled={false}
                fieldType="number"
                is_error={false}
                placeholder="Enter number of revisions"
              />
              {errors.revision && touched.revision && (
                <div className="error-message text-danger">
                  {errors.revision}
                </div>
              )}
            </div>

            <div className="mb-4">
              <label className="label pb-2">Message</label>
              <ReactQuill
                theme="snow"
                value={values.message}
                onChange={(content) => setFieldValue("message", content)}
              />
              {errors.message && touched.message && (
                <div className="error-message text-danger">
                  {errors.message}
                </div>
              )}
            </div>

            <div className="mb-4">
              <label className="label pb-2">Cover Letter</label>
              <ReactQuill
                theme="snow"
                value={values.cover_letter}
                onChange={(content) => setFieldValue("cover_letter", content)}
              />
              {errors.cover_letter && touched.cover_letter && (
                <div className="error-message text-danger">
                  {errors.cover_letter}
                </div>
              )}
            </div>

            <div className="additional-costs mb-4">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <label className="label pb-2">Additional Costs (optional)</label>
                <Button
                  type="button"
                  buttonText="Add Cost"
                  onClickHandler={() => addCost()}
                  className="task-btn"
                  style={{borderRadius:'0'}}
                  
                />
              </div>

              {costs.map((cost, index) => (
                <div key={index} className="cost-item border rounded p-3 mb-3">
                  <div className="row">
                    <div className="col-md-3">
                      <label className="form-label">Cost</label>
                      <input
                        type="number"
                        className="form-control"
                        value={cost.cost}
                        onChange={(e) =>
                          updateCost(index, "cost", parseFloat(e.target.value))
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">Currency</label>
                      <input
                        type="text"
                        className="form-control"
                        value={details.currency}
                        disabled
                      />
                    </div>
                    <div className="col-md-5">
                      <label className="form-label">Description</label>
                      <input
                        type="text"
                        className="form-control"
                        value={cost.description}
                        onChange={(e) =>
                          updateCost(index, "description", e.target.value)
                        }
                      />
                    </div>
                    <div className="col-md-1 d-flex align-items-end">
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={() => removeCost(index)}
                      >
                        X
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="d-flex justify-content-end mt-4">
              <Button
                className="task-btn"
                buttonText="Submit"
                type="submit"
                isLoading={isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
